
import { defineComponent, reactive, watch } from 'vue';
import Icone from '@/core/components/Icone.vue';
import MensagemSemDados from '@/core/components/Tela/MensagemSemDados.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useGradeBase } from '@/core/composables/GradeBase';
import { IDTOProdutoDefinicao } from '@/models/DTO/Cadastros/Produtos/IDTOProdutoDefinicao';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IParametrosConsulta } from '@/core/models/Consulta/IParametrosConsulta';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { IParametrosConsultaProdutoDefinicao } from '@/models/ParametrosRequisicao/Cadastros/Produtos/IParametrosConsultaProdutoDefinicao';
import ServicoProduto from '@/servicos/Cadastros/Produtos/ServicoProduto';
import { useModalBase } from '@/core/composables/ModalBase';
import BuscaGeral from '@/core/components/BuscaAvancada/BuscaGeral.vue';
import { IOrdenacao } from '@/core/models/Consulta/IOrdenacao';
import { ECustomRenderRow, IColumn } from '@/core/models/AntDesign/IColumn';

export default defineComponent({
  name: 'BuscarProdutosModal',
  props: {
    visivel: {
      type: Boolean,
    },
    titulo: {
      type: String,
      default: 'Selecionar produtos',
    },
    parametrosConsultaDefinicao: {
      type: Object as () => IParametrosConsultaProdutoDefinicao,
    },
    empresas: {
      type: Array as () => number[],
      required: true,
    },
  },
  components: {
    Icone,
    MensagemSemDados,
    BuscaGeral,
  },
  emits: ['aplicarFiltros', 'confirmadoSelecaoProdutos', 'update:visivel'],
  setup(props, { emit }) {
    const { telaBase, apresentarMensagemAlerta } = useTelaBase();
    const { modalBase } = useModalBase(props, emit);
    const { gradeBase, preenchePropriedadesNosFiltrosEOrdenacao, defineValoresIniciaisGrade } = useGradeBase();
    const servicoProduto = new ServicoProduto();

    const state = reactive({
      ordenacaoCampos: [] as IOrdenacao[],
      colunas: [] as IColumn[],
      produtos: [] as IDTOProdutoDefinicao[],
      buscaRapida: '',
      windowHeight: (window.innerHeight - 300),
      windowWidth: (window.innerWidth - 100),
    });

    function preencherColunas() {
      state.colunas = [
        {
          title: 'Código Definição', dataIndex: 'codigoDefinicao', key: 'CodigoDefinicao', position: 0, visible: false, ordering: true,
        },
        {
          title: 'Código Interno', dataIndex: 'codigoInterno', key: 'CodigoInternoProduto', width: 80, ellipsis: true, position: 1, visible: true, ordering: true, align: 'right',
        },
        {
          title: 'Produto', dataIndex: 'produto', key: 'DescricaoProduto', width: 300, ellipsis: true, position: 2, visible: true, ordering: true, align: 'left',
        },
        {
          title: 'Unidade', dataIndex: 'unidade', key: 'UnidadeProduto', width: 70, ellipsis: true, position: 3, visible: true, ordering: true, align: 'center',
        },
        {
          title: 'Marca', dataIndex: 'marca', key: 'MarcaProduto', width: 100, ellipsis: true, position: 4, visible: true, ordering: true, align: 'left',
        },
      ];
    }

    function limparTela() {
      state.produtos = [] as IDTOProdutoDefinicao[];
      state.buscaRapida = '';
      gradeBase.buscaAvancada.filtrosAdicionados = [];
      gradeBase.buscaAvancada.chaveFiltrosAdicionados = 0;
      gradeBase.ordenacaoSelecionada = [];
      gradeBase.codigosSelecionados = [];
      preencherColunas();
    }

    const onSelectChange = (selectedRowKeys: any) => {
      gradeBase.codigosSelecionados = selectedRowKeys;
    };

    async function buscarProdutos() {
      state.produtos = [];
      if (props.empresas.length === 0) {
        apresentarMensagemAlerta('Nenhuma empresa foi selecionada!');
        return;
      }

      gradeBase.filtrosAplicados = [];
      gradeBase.buscaAvancada.filtrosAdicionados.forEach((item) => {
        gradeBase.filtrosAplicados.push(item.filtro);
      });

      const parametrosConsulta = {} as IParametrosConsulta;
      parametrosConsulta.valorBuscaRapida = state.buscaRapida;
      parametrosConsulta.empresas = props.empresas;
      parametrosConsulta.numeroPagina = gradeBase.paginacao.current;
      parametrosConsulta.qtdeRegistrosPagina = gradeBase.paginacao.pageSize;
      parametrosConsulta.qtdeRegistrosTotal = gradeBase.paginacao.total;
      parametrosConsulta.ordenacao = Array<string>();

      gradeBase.ordenacaoSelecionada.forEach((item) => {
        parametrosConsulta.ordenacao.push(`${item.identificador}|${item.ordem}`);
      });

      gradeBase.buscandoDados = true;
      const listaPaginada = await servicoProduto.buscaAvancadaDefinicoes(parametrosConsulta, gradeBase.filtrosAplicados, props.parametrosConsultaDefinicao);
      if (listaPaginada !== undefined && listaPaginada !== null) {
        if (UtilitarioGeral.validaLista(listaPaginada.dados)) {
          state.produtos = listaPaginada.dados;
          gradeBase.paginacaoApi = listaPaginada.metaData;
        }
      }
      gradeBase.buscandoDados = false;
    }

    function confirmar() {
      if (UtilitarioGeral.validaLista(gradeBase.codigosSelecionados)) {
        emit('confirmadoSelecaoProdutos', gradeBase.codigosSelecionados);
        modalBase.computedVisivel = false;
      } else {
        apresentarMensagemAlerta('Nenhum produto foi selecionado!');
      }
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      limparTela();
      if (modalBase.computedVisivel) {
        gradeBase.propriedadesConsulta = await servicoProduto.obterPropriedadesConsultaDefinicoes();
        await preenchePropriedadesNosFiltrosEOrdenacao();
        await defineValoresIniciaisGrade();
      }
      telaBase.carregando = false;
    });

    return {
      props,
      state,
      modalBase,
      telaBase,
      gradeBase,
      ECustomRenderRow,
      UtilitarioMascara,
      onSelectChange,
      buscarProdutos,
      confirmar,
    };
  },
});
