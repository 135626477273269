
import { defineComponent, reactive, watch } from 'vue';
import Icone from '@/core/components/Icone.vue';
import { useTelaBase } from '@/core/composables/TelaBase';
import { useModalBase } from '@/core/composables/ModalBase';
import Card from '@/core/components/Tela/Card.vue';
import { IConfiguracaoBoletos, IConfiguracaoBoletosImpressao, IConfiguracaoBoletosMensagem } from '@/models/Entidades/Cadastros/Financeiro/IConfiguracoesBoletos';
import SelecionarGenericoString from '@/core/components/Tela/SelecionarGenericoString.vue';
import { IOption, IOptionString } from '@/core/models/AntDesign/IOption';
import SelecionarTipoLayoutRemessa from '@/components/Cadastros/Financeiro/SelecionarTipoLayoutRemessa.vue';
import SelecionarGenerico from '@/core/components/Tela/SelecionarGenerico.vue';
import ServicoBoletos from '@/servicos/Financeiro/ServicoBoleto';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { ELayoutRemessaBoletos } from '@/models/Enumeradores/Financeiro/ELayoutRemessaBoletos';
import SelecionarTipoImpressaoBoletos from '@/components/Financeiro/TitulosFinanceiros/Boletos/SelecionarTipoImpressaoBoletos.vue';
import { ETipoImpressaoBoleto } from '@/models/Enumeradores/Financeiro/ETipoImpressaoBoleto';
import SelecionarServidorEmailNotificacao from '@/components/Financeiro/SelecionarServidorEmailNotificacao.vue';
import EditorHtml from '@/core/components/Tela/EditorHtml.vue';
import SelecionarVariavelCobranca from '@/components/Cadastros/Financeiro/SelecionarVariavelCobranca.vue';
import ServicoBanco from '@/servicos/Cadastros/Financeiro/ServicoBanco';
import { IBanco } from '@/models/Entidades/Cadastros/Financeiro/IBanco';

export default defineComponent({
  name: 'ConfiguracaoBoletosModal',
  props: {
    visivel: {
      type: Boolean,
    },
    empresa: {
      type: Number,
      required: true,
    },
    banco: {
      type: Number,
      required: true,
    },
    configuracao: {
      type: Object as () => IConfiguracaoBoletos,
      required: true,
    },
  },
  components: {
    Card,
    Icone,
    SelecionarGenerico,
    SelecionarGenericoString,
    SelecionarTipoLayoutRemessa,
    SelecionarTipoImpressaoBoletos,
    SelecionarServidorEmailNotificacao,
    EditorHtml,
    SelecionarVariavelCobranca,
  },
  emits: ['update:visivel', 'confirmacao'],
  setup(props, { emit }) {
    const { telaBase, apresentarMensagemAlerta } = useTelaBase();
    const { modalBase } = useModalBase(props, emit);
    const servicoBoletos = new ServicoBoletos();
    const servicoBanco = new ServicoBanco();

    const state = reactive({
      configuracao: {} as IConfiguracaoBoletos,
      banco: {} as IBanco,
      especies: [] as IOptionString[],
      carteiras: [] as IOption[],
      codigosProtesto: [] as IOption[],
      modalidadesCobranca: [] as IOption[],
      tiposImpressaoUsados: [] as number[],
      codigoTransmissao: '',
      mensagem: '',
      impressao: {} as IConfiguracaoBoletosImpressao,
      variavelSelecionada: '@NUMERO_TITULO',
      exibirRestaurarTemplate: false,
    });

    function limparTela() {
      state.configuracao = {} as IConfiguracaoBoletos;
      state.configuracao.codigo = 0;
      state.configuracao.codigoEmpresa = props.empresa;
      state.configuracao.especie = 'DM';
      state.configuracao.aceite = true;
      state.configuracao.codigoTransmissaoCnab240 = '';
      state.configuracao.codigoTransmissaoCnab400 = '';
      state.configuracao.layoutRemessa = ELayoutRemessaBoletos.Cnab240;
      state.configuracao.versaoRemessa = 1;
      state.configuracao.proximoNossoNumero = 1;
      state.configuracao.sequenciaRemessa = 1;
      state.configuracao.diasDevolucao = 15;
      state.configuracao.diasProtestar = 15;
      state.configuracao.gerarMulta = true;
      state.configuracao.gerarJuros = true;
      state.configuracao.dvBeneficiario = '';
      state.configuracao.gerarDesconto = true;
      state.configuracao.impressoes = [] as IConfiguracaoBoletosImpressao[];
      state.configuracao.mensagens = [] as IConfiguracaoBoletosMensagem[];
      state.mensagem = '';
      state.impressao = {} as IConfiguracaoBoletosImpressao;
      state.impressao.tipo = ETipoImpressaoBoleto.Geral;
      state.impressao.comprovanteEntrega = false;
      state.impressao.reciboPagador = false;
      state.impressao.carne = false;
      state.tiposImpressaoUsados = [] as number[];
      state.variavelSelecionada = '@NUMERO_TITULO';
      state.configuracao.impressoes.push(state.impressao);
      state.exibirRestaurarTemplate = false;
    }

    function atualizarTiposSelecionados() {
      state.tiposImpressaoUsados = [] as number[];
      state.configuracao.impressoes.forEach((i) => {
        state.tiposImpressaoUsados.push(i.tipo);
      });

      if (!state.tiposImpressaoUsados.includes(1)) {
        state.impressao.tipo = 1;
      } else if (!state.tiposImpressaoUsados.includes(2)) {
        state.impressao.tipo = 2;
      } else if (!state.tiposImpressaoUsados.includes(3)) {
        state.impressao.tipo = 3;
      } else {
        state.impressao.tipo = 0;
      }
    }

    function restaurarTemplateEmail() {
      state.configuracao.corpoEmailCobranca = servicoBoletos.obterTemplateEmailCobranca();
    }

    function adicionarMensagem() {
      if (state.mensagem === '') {
        apresentarMensagemAlerta('A mensagem está vazia!');
        return;
      }

      const mensagem = {} as IConfiguracaoBoletosMensagem;
      mensagem.mensagem = state.mensagem;
      mensagem.codigoConfiguracaoBoletos = state.configuracao.codigo;
      state.configuracao.mensagens.push(mensagem);
      state.mensagem = '';
    }

    function removerMensagem(index: number) {
      state.configuracao.mensagens.splice(index, 1);
    }

    function adicionarImpressao() {
      let utilizado = false;
      state.configuracao.impressoes.forEach((i) => {
        if (i.tipo === state.impressao.tipo) {
          utilizado = true;
        }
      });
      if (utilizado) {
        apresentarMensagemAlerta('O tipo de impressão escolhido já esta em uso!');
        return;
      }

      state.impressao.codigoConfiguracaoBoletos = state.configuracao.codigo;
      state.configuracao.impressoes.push(state.impressao);
      state.impressao = {} as IConfiguracaoBoletosImpressao;
      state.impressao.tipo = ETipoImpressaoBoleto.Geral;
      state.impressao.comprovanteEntrega = false;
      state.impressao.reciboPagador = false;
      state.impressao.carne = false;
      state.tiposImpressaoUsados.push(Number(state.impressao.tipo));
      atualizarTiposSelecionados();
    }

    function removerImpressao(index: number) {
      state.configuracao.impressoes.splice(index, 1);
      atualizarTiposSelecionados();
    }

    async function obterDefinicoesBanco() {
      state.banco = await servicoBanco.obter(props.banco);
      const definicoes = await servicoBoletos.obterDefinicoesBanco(props.empresa, props.banco);
      state.especies = [] as IOptionString[];
      state.carteiras = [] as IOption[];
      state.codigosProtesto = [] as IOption[];
      state.modalidadesCobranca = [] as IOption[];

      definicoes.especies.forEach((e) => {
        const item = {} as IOptionString;
        item.label = `${e.identificador} - ${e.descricao}`;
        item.value = e.identificador;
        state.especies.push(item);
      });

      definicoes.carteiras.forEach((c) => {
        const item = {} as IOption;
        item.label = `${c.identificador} - ${c.descricao}`;
        item.value = Number.parseInt(c.identificador, 10);
        state.carteiras.push(item);
      });

      definicoes.codigosProtesto.forEach((c) => {
        const item = {} as IOption;
        item.label = `${c.identificador} - ${c.descricao}`;
        item.value = Number.parseInt(c.identificador, 10);
        state.codigosProtesto.push(item);
      });

      definicoes.modalidadesCobranca.forEach((m) => {
        const item = {} as IOption;
        item.label = `${m.identificador} - ${m.descricao}`;
        item.value = Number.parseInt(m.identificador, 10);
        state.modalidadesCobranca.push(item);
      });
    }

    function adicionarVariavelAssunto() {
      state.configuracao.assuntoEmailCobranca += ` ${state.variavelSelecionada}`;
      state.variavelSelecionada = '@NUMERO_TITULO';
    }

    function adicionarVariavelCorpo() {
      state.configuracao.corpoEmailCobranca += ` ${state.variavelSelecionada}`;
      state.variavelSelecionada = '@NUMERO_TITULO';
    }

    function verificarCampos() {
      if (state.configuracao.especie === '' || state.configuracao.especie === undefined) {
        apresentarMensagemAlerta('A espécie deve ser informada!');
        return false;
      }

      if (state.configuracao.carteira === 0 || state.configuracao.carteira === undefined) {
        apresentarMensagemAlerta('A carteira deve ser informada!');
        return false;
      }

      if (state.configuracao.proximoNossoNumero === 0 || state.configuracao.proximoNossoNumero === undefined) {
        apresentarMensagemAlerta('O próximo nosso número deve ser informado!');
        return false;
      }

      if (state.configuracao.sequenciaRemessa === 0 || state.configuracao.sequenciaRemessa === undefined) {
        apresentarMensagemAlerta('A sequência da remessa deve ser informada!');
        return false;
      }

      if (state.configuracao.codigoBeneficiario === '' || state.configuracao.codigoBeneficiario === undefined) {
        apresentarMensagemAlerta('O código do beneficiário deve ser informado!');
        return false;
      }

      if (state.banco.codigoBanco === '001' && state.configuracao.codigoBeneficiario.length !== 4 && state.configuracao.codigoBeneficiario.length !== 6 && state.configuracao.codigoBeneficiario.length !== 7) {
        apresentarMensagemAlerta('O código do beneficiário deve ter 4, 6 ou 7 caracteres!');
        return false;
      }

      if (state.banco.codigoBanco === '001' && state.configuracao.variacaoCarteira === '') {
        apresentarMensagemAlerta('A variação da carteira deve ser informada!');
        return false;
      }

      if (state.configuracao.codigoProtesto === 0 || state.configuracao.codigoProtesto === undefined) {
        apresentarMensagemAlerta('O código de protesto deve ser informado na aba de dados de cobrança!');
        return false;
      }

      if (state.configuracao.impressoes.length === 0) {
        apresentarMensagemAlerta('Ao menos a impressão de tipoo geral deve ser definida!');
        return false;
      }

      return true;
    }

    function salvar() {
      const verificacao = verificarCampos();
      if (!verificacao) return;

      emit('confirmacao', state.configuracao);
      modalBase.computedVisivel = false;
    }

    watch(async () => modalBase.computedVisivel, async () => {
      telaBase.carregando = true;
      limparTela();
      if (modalBase.computedVisivel) {
        await obterDefinicoesBanco();
        if (props.configuracao !== null && props.configuracao !== undefined && props.configuracao.proximoNossoNumero > 0) {
          state.configuracao = UtilitarioGeral.clonarObjeto(props.configuracao);
          state.configuracao.impressoes.forEach((i) => {
            state.tiposImpressaoUsados.push(i.tipo);
          });
          atualizarTiposSelecionados();
          if (state.configuracao.layoutRemessa === ELayoutRemessaBoletos.Cnab240) {
            state.codigoTransmissao = state.configuracao.codigoTransmissaoCnab240;
          } else {
            state.codigoTransmissao = state.configuracao.codigoTransmissaoCnab400;
          }
        }
      }
      telaBase.carregando = false;
    });

    return {
      props,
      state,
      telaBase,
      modalBase,
      restaurarTemplateEmail,
      adicionarMensagem,
      adicionarImpressao,
      removerMensagem,
      removerImpressao,
      adicionarVariavelAssunto,
      adicionarVariavelCorpo,
      salvar,
    };
  },
});
